  // 採用情報
  .p-recruit {
    .l-section {
      padding-bottom: 0;
    }
    .l-section__inner {
      margin: 0 auto;
      padding-bottom: 0;
    }

    .l-businessCard {
      padding-bottom: 60px;
      margin-bottom: 0;
      border-bottom: dashed 2px #C6C6C6;
      @include mq-max(xs) {
        padding-bottom: 40px;
      }
    }
  }

  // 採用情報子ページ
  .p-recruitChild {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }