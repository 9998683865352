.c-cardCol3 {
  &__thumb {
    width: 100%;
    padding-top: 62.01117%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 8px;
    border-radius: 5px;
  }
}