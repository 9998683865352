.u-before-after {
  &__inner {
    padding: 10px 22px 24px;
    background-color: $white;
    figcaption {
      font-size: 21px;
      font-weight: 500;
      letter-spacing: 0.06em;
    }
    &--border {
      border: solid 1px $gray;
      }
  }
  // css調整
  .c-ttl-3 {
    margin-top: 10px;
  }
}