///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {

  //h2見出し
  &-2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
    letter-spacing: 0.06em;

    @include mq-max(xs) {
      font-size: 28px;
    }

    &>small {
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: $keyColor;
      letter-spacing: 0.06em;
      font-family: "Aleo";
    }

    &>span {
      padding: 15px 0;
      display: inline-block;
      position: relative;

      &:before,
      &:after {
        content: "";
        background-image: linear-gradient(to right, $keyColor, $keyColor 30px, transparent 30px, transparent 40px);
        background-size: 40px 3px;
        background-repeat: repeat-x;
        width: 100%;
        height: 3px;
        position: absolute;

        @include mq-max(xs) {
          background-image: linear-gradient(to right, #000000, #000000 10px, transparent 10px, transparent 20px);
          background-size: 20px 3px;
        }
      }

      &:before {
        top: 10px;
      }

      &:after {
        bottom: 8px;
        left: 0;
      }

    }

    &Wrap {
      display: flex;
      align-items: center;
      margin-bottom: 60px;

      @include mq-max(lg) {
        display: block;
      }
    }

    &__discription {
      margin-left: 50px;

      @include mq-max(lg) {
        margin-left: 0;
      }
    }

    &--side {
      display: flex;
      align-items: center;

      >img {
        display: block;
        margin-right: 10px;
      }
    }

    &--catch {
        &> span {
          display: block;
          &:before {
            content: none;
          }
        }
      }


    &--fzLarge {
      font-size: 36px;

      @include mq-max(xs) {
        font-size: 30px;
      }
    }

    &--simple {
      font-size: 36px;
      letter-spacing: 0.06em;

      @include mq-max(sm) {
        font-size: 30px;
      }
    }
  }

  //h3見出し
  &-3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
    letter-spacing: 0.06em;

    @include mq-max(xs) {
      font-size: 20px;
    }

    &--bg {
      letter-spacing: 0.06em;
      padding: 16px;
      background-color: $lightGray;
      border-radius: 5px;
    }

    &--line {
      position: relative;
      padding-left: 12px;

      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 2px;
        position: absolute;
        left: 0;
      }

      &:before {
        background-color: $gray;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
      }

      &:after {
        background-color: $keyColor;
        top: 0;
        height: 50%;
      }
    }

    &--large {
      font-size: 36px;
      font-weight: 500;
    }

    &--fwb {
      font-weight: 600;
    }
  }

  //modify
  &--center {
    text-align: center;

    &>small {
      font-weight: 400;
    }
  }

  &--mbLarge {
    margin-bottom: 30px;
  }
}