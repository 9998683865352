.l-header {
  background-color: $white;

  @if $is-fixed==true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0 0 34px;
    position: relative;
    z-index: 100;

    @include mq-max(md) {
      display: block;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
      box-shadow: none;
    }
  }

  h1,
  .logo {
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 0;
    display: inline-block;
    width: 289.5px; // 状況によって変更可

    @include mq-max(md) {
      width: 160px;
    }

    @include mq-max(md) {
      width: 145px;
      margin: 0;
      line-height: 0;
    }

    img {
      width: 100%;
    }
  }

  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }

    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 0;

    .l-header--fixed & {
      h1 {
        margin-top: 10px;

        @include mq-max(md) {
          margin-left: 10px;
        }
      }
    }

    @include mq-max(md) {
      padding: 20.7px 15px;
      background-color: $white;
      line-height: 0;

      .l-header--fixed & {
        background-color: transparent;
      }
    }
  }

  // TOP用アニメーション
  &--fixed {
    position: absolute;
    background-color: transparent;

    .l-header {
      &__inner {
        max-width: calc(100% - 20px);

        @include mq-max(md) {
          max-width: 100%;
        }
      }
    }

    opacity: 0;
  }

  &--isvisible {
    opacity: 1;
  }

}



///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ハンバーガーメニュー

///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max(md) {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;

    &:after {
      content: 'メニュー';
      font-size: 10px;
      font-weight: 600;
      color: $keyColor;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: .35s ease-in-out;
      .l-header--fixed & {
        color: $white;
      }
    }

    span {
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 3px $keyColor;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      .l-header--fixed & {
        border-bottom: solid 3px $white;
      }

      &:nth-child(1) {
        top: 9px;
      }

      &:nth-child(2) {
        top: 18px;
      }

      &:nth-child(3) {
        top: 27px;
      }
    }

    &.is-active {
      span {
        &:nth-child(1) {
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }
  }
}