//アニメーション

//TOP メインヴィジュアル　スクロール
.c-anime {
  &--scroll {
    animation: scrollBar 3s linear infinite;
  }
}


//keyframes
@keyframes scrollBar {
  0% {
    left: 0;
  }
  100% {
    left: 30%;
  }
}