//カード
.c-card {
  &__item {
    &>a {
      display: block;
      transition: all $transition;
      &:after {
        content: none;
      }
      &:hover {
        transform: translateY(-10px);
        @include mq-max(md) {
          transform: none;
        }
        .c-card__detail {
          &:after {
            right: -20px;
          }
        }
      }
    }
  }
  &__inner {
    box-shadow: $box-shadow;
  }
  &__thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: calc(233 / 360 * 100%);
  }
  &__body {
    padding: 13px 24px 45px;
    position: relative;
    background-color: $white;
    @include mq-max(sm) {
      padding: 13px 22px 45px;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    color: $keyColor;
    margin-bottom: 0;
  }
  &__desc {
    color: $textColor;
    margin: 0;
    line-height: 1.5;
  }
  &__detail {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: $keyColor;
    margin: 0;
    display: inline-block;
    position: absolute;
    bottom: 18px;
    right: 28px;
    &:after {
      @include fa('f138');
      position: absolute;
      top: 54%;
      right: -18px;
      transform: translateY(-50%);
      transition: all $transition;
    }
  }
}


//小さめのカード
.c-smallCard {
  a {
    &:after {
      content: none;
    }
  }
  // &__item {}
  &__inner {
    box-shadow: $box-shadow;
  }
  &__thumb {
    width: 100%;
    padding-top: (125 / 250 * 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: 1px solid $gray;
  }
  &__body {
    background-color: $white;
    padding: 14px 15px;
  }
  &__name {
    line-height: 1.5;
    font-size: 18px;
    font-weight: 600;
  }

  &--link {
    .c-smallCard__name {
      color: $textColor;
      font-size: 18px;
      margin: 0 0 2px;
    }
    .c-smallCard__detail {
      color: $keyColor;
      font-size: 12px;
      font-weight: 600;
      &:after {
        @include fa('f35d');
        margin-left: 5px;
      }
    }
    a {
      transition: all $transition;
      display: block;
      &:hover {
        transform: translateY(-10px);
        @include mq-max(md) {
          transform: none;
        }
      }
    }
  }
}


//ユニークカード
.c-uniqueCard {
  &__list {}
  &__item {}
  &__index {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    background-color: $keyColor;
    color: $white;
    padding: 3px 10px;
  }
  &__img {
    width: 100%;
    padding-top: (222 / 360 * 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }
}