.l-navigation {
  @include mq-max(md) {
    display: none;

    html.open &.active>ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp}); //画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }

  &.active {
    transform: translateY(0%);
  }

  &__inner {
    padding: 0;
    display: flex;

    @include mq-max(md) {
      display: block;
      height: calc(100vh - #{$headerHeight-sp});
      overflow-y: scroll;
    }
  }

  &__item {
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    @include mq-max(md) {
      margin-right: 0;
      border-top: 1px solid $gray;

      &:last-child {
        border-bottom: 1px solid $gray;
      }
    }

    a {
      font-size: 15px;

      @include mq-max(md) {
        background-color: $white;
        position: relative;

        &:after {
          @include fa('f054');
          position: absolute;
          top: 50%;
          right: 28px !important;
          transform: translateY(-50%);
        }
      }
    }

    &>a {
      text-decoration: none;
      color: $keyColor;
      font-weight: 600;
      display: inline-block;
      padding: 33px 19px;
      position: relative;

      @include mq-max(bp1200) {
        padding: 33px 15px;
      }

      @include mq-max(md) {
        display: block;
        padding: 11px 45px 11px 28px;
        font-size: 16px;

        &:before {
          display: none !important;
        }
      }

      &:hover {
        color: $keyColor;

        &:before {
          width: 95%;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 0%;
        height: 2px;
        background-color: $keyColor;
        position: absolute;
        bottom: 0;
        left: (100% - 95%) / 2;
        transition: all $transition;
      }

      &.current {
        @include mq-max(md) {
          color: $keyColor;
        }

        &:before {
          width: 95%;
        }
      }

      // --fixd
      .l-header--fixed & {
        color: white;

        @include mq-max(md) {
          color: $keyColor;
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a {
        position: relative;

        &:after {
          @include fa('f107');
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
          line-height: 1;
          font-size: 16px;

          @include mq-max(md) {
            @include fa('f067');
            right: 16px;
          }
        }

        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }

        &[href^="javascript:void(0)"] {
          pointer-events: auto;
        }
      }

      //クリックで展開するようにするためにコメントアウト（is-openedの下に移動）
      // &:hover {
      //   .l-dropDown {
      //     top: $headerHeight; // headerの高さ
      //     opacity: 1;
      //     visibility: visible;
      //     @include mq-max(md) {
      //       top: auto;
      //     }
      //     a {
      //       transition: color $transition,
      //                   height $transition;
      //       &:hover {
      //         color: $keyColor;
      //       }
      //     }
      //   }
      // }
      .l-dropDown {
        position: absolute;
        top: $headerHeight - 30px; // headerの高さ - 30px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        padding: 0 24px;
        background-color: $white;
        overflow: hidden;

        @include mq-max(md) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          padding: 0;
          border-radius: 0;
        }

        li {
          border-bottom: 1px solid $gray;

          @include mq-max(md) {
            border-bottom: none;
          }

          &:last-child {
            border: none;
          }

          a {
            background-color: $white;
            padding: 18px 0;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;

            @include mq-max(md) {
              line-height: 0;
              height: 0;
              padding: 0;
              opacity: 0;
              transition: height $transition,
                opacity 0.4s,
                padding $transition,
                line-height $transition;
            }

            &:after {
              @include mq-max(md) {
                font-size: 13px;
              }
            }
          }
        }
      }

      &.is-opened {
        .l-dropDown {
          top: calc(#{$headerHeight} + 20px); // headerの高さ
          opacity: 1;
          visibility: visible;

          @include mq-max(md) {
            top: auto;
          }

          a {
            transition: color $transition,
              height $transition;

            &:hover {
              color: $keyColor;
            }
          }
        }

        &>a {
          @include mq-max(md) {
            &:after {
              content: '\f068';
            }
          }
        }

        >.l-dropDown {
          @include mq-max(md) {
            opacity: 1;
            display: block;

            >li {
              border-bottom: 1px solid $gray;

              &:first-child {
                border-top: 1px solid $gray;
              }

              &:last-child {
                border-bottom: none;
              }
            }

            >li>a {
              opacity: 1;
              line-height: 1.5;
              height: 46px;
              padding: 11px 45px 11px 50px;
              background-color: $lightGray;
            }
          }
        }
      }
    }
  }
}

//強調メニュー（右端）
.l-nav {
  margin-left: 13px;
  margin-right: 20px;

  @include mq-max(md) {
    margin-left: 0;
    margin-right: 0;
    background-color: $keyColor;
  }

  &__btns {
    display: flex;
    height: 100%;
  }

  &__item {
    @include mq-max(md) {
      width: 100%;
    }

    &--1 {
      &>a {
        &>span {
          &:before {
            width: 23px;
            height: 30px;
            background: url('/inc/image/common/ico_mail-black.png')no-repeat center / contain;

            @include mq-max(md) {
              background: url('/inc/image/common/ico_mail-white.png')no-repeat center / contain;
            }

            margin-bottom: 3px;

            .l-header--fixed & {
              background: url('/inc/image/common/ico_mail-white.png')no-repeat center / contain;

            }
          }
        }
      }
    }

    &>a {
      color: $keyColor;
      position: relative;
      display: inline-block;
      width: 118px;
      height: 100%;
      transition: all $transition;

      .l-header--fixed & {
        color: $white;
      }

      @include mq-max(md) {
        width: 100%;
        height: 60px;
      }

      &:hover {
        color: $white;

        &>span {
          background-color: $keyColor;
          .l-header--fixed & {
            background-color: transparent;
            opacity: .7;
          }

          &:before {
            background: url('/inc/image/common/ico_mail-white.png')no-repeat center / contain;
            transition: all $transition;
          }
        }

        .l-header--fixed & {
          color: $white;
        }
      }

      &>span {
        color: inherit;
        display: inline-block;
        font-size: 10px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: solid 3px $keyColor;
        border-radius: 5px;
        vertical-align: middle;
        padding: 2px 0;

        @include mq-max(md) {
          color: $white;
          padding-top: 28px;
          position: relative;
        }

        .l-header--fixed & {
          border: solid 3px $white;

          @include mq-max(md) {
            border: none;
          }
        }

        &:before {
          content: '';
          display: inline-block;
          margin-left: auto;
          margin-right: auto;
          margin-right: 5px;

          @include mq-max(md) {
            margin-right: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
          }

        }
      }
    }
  }
}


//ドロップダウン展開時のフィルター
.l-filter {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  transition: opacity $transition;
  pointer-events: none;

  @include mq-max(md) {
    display: none !important;
  }

  &.is-active {
    opacity: .4;
    pointer-events: auto;
  }
}